<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;">线上考试管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back();">学员列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">选择学员</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="学员来源" class="searchboxItem ci-full">
              <span class="itemLabel">学员来源:</span>
              <el-select v-model="retrievalData.userSource" filterable size="small" placeholder="请选择考试科目">
                <el-option v-for="item in studentSourceList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div title="招生/考务名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem;">招生/考务名称:</span>
              <el-input v-model="retrievalData.projectName" type="text" size="small" placeholder="招生/考务名称" clearable />
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button type="primary" class="bgc-bv" round @click="determineChoice">确定</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe @selection-change="handleSelectionChange"
              :row-key="(row) => row.userCacheId">
              <el-table-column type="selection" :reserve-selection="true" width="55" align="left" />
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" minWidth="100" />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" minWidth="200" />
              <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" minWidth="120" />
              <!-- <el-table-column label="字典" align="left" show-overflow-tooltip prop="examType" minWidth="120">
                <template slot-scope="scope">
                  {{ $setDictionary("EXAMINATION_EXAM_TYPE", scope.row.examType) }}
                </template>
              </el-table-column> -->
              <!-- <el-table-column label="字段" align="left" show-overflow-tooltip min-width="300px">
                <template slot-scope="scope">
                  {{ scope.row.startTime | moment }} 至 {{ scope.row.endTime | moment }}
                </template>
              </el-table-column> -->
              <!-- <el-table-column label="操作" align="center" width="120px" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="deleteStudents(scope.row)">删除</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="toResetYourPassword(scope.row)">重置密码</el-button>
                </template>
              </el-table-column> -->
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "examination_onlineTest_studentsChoose",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 上个页面传过来的数据
      lastPageData: {
        examinationId: "", // 考试id
        activityId: "", // 考务id
      },
      // 检索数据
      retrievalData: {
        userSource: "", // 学员来源
        projectName: "", // 招生/考务名称
      },
      // 学员来源 - 数据
      studentSourceList: [],
      // 列表已勾选数据
      multipleSelection: [],
    };
  },
  doNotInit: true,
  created() {
    this.lastPageData.examinationId = this.$route.query?.examinationId ?? "";
    this.lastPageData.activityId = this.$route.query?.activityId ?? "";
    this.dataDictionary();
  },
  watch: {},
  computed: {},
  mounted() { },
  methods: {
    // 获取 - 数据字典
    dataDictionary() {
      // 学员来源
      const list1 = [];
      if (!this.lastPageData.activityId) {
        list1.push({
          value: '00',
          label: '报名',
        });
      }
      list1.push({
        value: '01',
        label: '考务',
      });
      this.studentSourceList = list1;
      this.retrievalData.userSource = this.studentSourceList[0]?.value ?? '';
      if (this.retrievalData.userSource) {
        this.getData();
      }
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        examinationId: this.$route.query?.examinationId
      };
      if (this.retrievalData.userSource) {
        params.userSource = this.retrievalData.userSource;
      }
      if (this.retrievalData.projectName) {
        params.projectName = this.retrievalData.projectName;
      }
      this.doFetch({
        url: "/biz/examination/user/selectChoiceList",
        params,
        pageNum,
      }, true, 6);
    },
    // 列表多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 确定
    determineChoice() {
      if(this.multipleSelection.length>0){
        let cData = {
          examinationId: this.lastPageData.examinationId,
          userDTOS: []
        }
        for (let i = 0; i < this.multipleSelection.length; i++) {
          cData.userDTOS.push(this.multipleSelection[i])
        }
        this.$post("/biz/examination/user/choiceUser", cData, 3000, true, 6)
          .then((res) => {
            this.$refs.multipleTable.clearSelection();
            this.$message.success('选择学员成功')
              this.$router.back()
            this.getData();
          })
          .catch((err) => {
            return;
          });
      } else {
        this.$message({
          message: '请勾选要加入的学员',
          type: 'warning'
        });
      }
    },
  },
};
</script>
<style lang="less" scoped></style>
  